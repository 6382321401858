<template>
  <b-modal
    :title="$t('members.edit.classifiers.actions.add-classifier')"
    :visible="visible"
    hide-footer
    centered
    no-close-on-backdrop
    @change="$emit('change', false)"
  >
    <b-spinner v-if="loading" small type="grow" class="d-block mx-auto text-primary"/>
    <template v-else-if="communityClassifiers.length <= 0">
      <p class="mb-2 mt-1">
        {{ $t("members.edit.no-items") }}
      </p>
    </template>
    <template v-else>
      <b-form>
        <b-form-group
          v-for="(value, name, index) in sortedCommunityClassifiers"
          :key="index"
        >
          <label class="font-weight-bold">{{ name }}</label>
          <v-select
            v-model="selectClassifier[name]"
            :multiple="isMultiple(value)"
            class="select-control p-0"
            clearable
            :options="value"
            :get-option-label="(a) => getOption(a)"
            :placeholder="isMultiple(value) ? $t('form.select-multiple-placeholder') : $t('form.select-placeholder')"
          />
        </b-form-group>
        <b-button
          variant="primary"
          type="submit"
          @click.prevent="addClassifier"
        >
          {{ $t("form.actions.save") }}
        </b-button>
      </b-form>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BButton, BForm,
} from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';

export default {
  name: 'ClassifierModal',
  components: {
    BModal,
    BFormGroup,
    BButton,
    vSelect,
    BForm,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    itemKey: {
      type: String,
      required: false,
    },
    visible: Boolean,
    morphType: {
      type: String,
      default: '',
    },
    modelType: {
      type: String,
      default: '',
    },
    classifiers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectClassifier: {},
      loading: false,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    classifiersByModelType() {
      return this.$store.getters.classifiers[this.modelType];
    },
    // To do: Refactoring with Classifiers.vue
    communityClassifiersData() {
      // console.log('this.$store.getters.classifiers', this.$store.getters.classifiers);
      if (this.classifiersByModelType) {
        return this.classifiersByModelType.unpaginated?.reduce((acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        }, []);
      }
      return [];
    },
    communityClassifiers() {
      return this.communityClassifiersData ? Object.values(this.communityClassifiersData) : [];
    },
    classifierOptions() {
      return this.communityClassifiers.map(({ name }) => translateTranslationTable(this.$store.state.locale.currentLocale, name));
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      this.communityClassifiers.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else if (newObject[
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            item.typeName,
          )
        ].filter((items) => translateTranslationTable(
          this.$store.state.locale.currentLocale,
          items.name,
        ) === translateTranslationTable(
          this.$store.state.locale.currentLocale,
          item.name,
        )).length === 0) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
  },
  watch: {
    classifiers() {
      this.classifiersPair();
    },
  },
  async created() {
    this.loading = true;
    await this.fetchCommunityClassifiers();
    this.classifiersPair();
    this.loading = false;
  },
  methods: {
    async fetchCommunityClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: this.modelType,
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        requestConfig: {
          //communitySlug: ,
          modelType: this.modelType,
          count: 5000,
          offset: 0,
        },
      });
      this.classifiersPair();
    },
    async addClassifier() {
      const itemSelected = [];
      let selected = Object.values(this.selectClassifier).filter(
        (item) => item !== null,
      );
      selected = selected.map((item) => (typeof item === 'string' ? [item] : item));
      const items = [];
      selected.map((item) => {
        if (Array.isArray(item)) {
          item.map((it) => items.push(it));
        } else {
          items.push(item);
        }
      });
      items.map((i) => {
        this.communityClassifiers.map((cc) => {
          if (cc.key === i.key) {
            itemSelected.push(cc.key);
          }
        });
      });
      let response;
      try {
        response = await this.$store.dispatch('AddClassifiers', {
          morphKey: this.itemKey,
          morphType: this.modelType,
          classifierKey: itemSelected,
          items,
        });
        this.selectedClassifier = {};
        this.$emit('change', false);
        this.$emit('profile-updated');
        this.$emit('profileUpdated', itemSelected);
        this.notifySuccess(this.$t('organizations.backoffice.settings.classifiers.messages.success'));
      } catch (error) {
        console.log(error);
      }
      return response;
    },
    translateTranslationTable,
    classifiersPair() {
      const itemClassifiers = {};
      this.classifiers.filter((classifier) => {
        if (
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName,
            )
          ]
        ) {
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName,
            )
          ].push(
            classifier,
          );
        } else {
          itemClassifiers[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              classifier.typeName,
            )
          ] = [classifier];
        }
      });
      this.selectClassifier = itemClassifiers;
    },
    isMultiple(value) {
      return value.every(({ multiple }) => multiple === true);
    },
    getOption(a) {
      if (typeof a.name === 'object') {
        return a?.name[this.locale] || Object.values(a?.name)[0];
      }
      return a.name;
    },
  },
};
</script>
